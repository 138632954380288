import { useSetAtom } from "jotai";
import { SetStateAction, useEffect, useRef, useState } from "react";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import EditItem from "../../common/EditItem";
import {
  createWorkOrder,
  updateWorkOrder,
  getWorkOrder,
  IWorkOrderStatus,
  getWorkOrderStatuses,
  IMetadata,
  getMetadata,
  IAsset,
} from "../../services/assetServices";
import ZoneSelector from "../zones/ZoneSelector";
import {
  IconButton,
  Label,
  Pivot,
  PivotItem,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import AssetSelector from "./AssetSelector";
import { useBoolean } from "@fluentui/react-hooks";
import IssueSelector from "./IssueSelector";
import {
  labelColumnStyle,
  valueColumnStyle,
} from "../../common/styles/FormsStyles";
import WorkOrderComments from "./WorkOrderComments";
import WorkOrderMap from "./WorkOrderMap";
import OtherInfo from "../../common/OtherInfo";

const WorkOrder = () => {
  const isSaved = useRef(false);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const [workOrderStatuses, setWorkOrderStatuses] = useState<
    IWorkOrderStatus[]
  >([]);
  const [workOrderId, setWorkOrderId] = useState<number>();
  const [issueId, setIssueId] = useState(0);
  const [assetId, setAssetId] = useState<number>();
  const [zoneId, setZoneId] = useState<number>();

  const [metadata, setMetadata] = useState<IMetadata>();
  const [otherInfo, setOtherInfo] = useState({});

  const [
    isIssueSelectorOpen,
    { setTrue: showIssueSelector, setFalse: hideIssueSelector },
  ] = useBoolean(false);

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        setWorkOrderStatuses(await getWorkOrderStatuses(abortController));
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
  }, []);

  return (
    <>
      <Pivot>
        <PivotItem headerText="Details">
          <Stack tokens={{ childrenGap: 10 }}>
            <Text variant="large">Work Order</Text>
            <ZoneSelector
              zoneId={zoneId}
              disabled={isSaved.current}
              onSelected={(id: number) => setZoneId(id)}
            />
            <AssetSelector
              assetId={assetId}
              disabled={isSaved.current}
              onSelected={(asset: IAsset) => setAssetId(asset.id)}
            />
            <Stack horizontal>
              <Label style={labelColumnStyle}>Issue Id</Label>
              <div
                style={{
                  position: "relative",
                  width: valueColumnStyle.width,
                  marginLeft: 0,
                }}
              >
                <TextField
                  style={valueColumnStyle}
                  value={issueId?.toString()}
                />
                <IconButton
                  iconProps={{ iconName: "Search" }}
                  styles={{
                    root: {
                      position: "absolute",
                      top: 2,
                      right: 2,
                      height: 28,
                    },
                  }}
                  onClick={() => showIssueSelector()}
                />
              </div>
              <IssueSelector
                isOpen={isIssueSelectorOpen}
                hideIssueSelector={hideIssueSelector}
                onIssueLinkClick={(issueId) => {
                  setIssueId(issueId);
                  hideIssueSelector();
                }}
              />
            </Stack>
            <EditItem
              getAction={async (abortController, id) => {
                const metadata = await getMetadata(
                  abortController,
                  "WorkOrder"
                );
                setMetadata(metadata);
                if (!id) {
                  return { otherInfo: "{}" };
                }
                const workOrder: any = await getWorkOrder(abortController, id);
                setWorkOrderId(id);
                setZoneId(workOrder.zoneId);
                setIssueId(workOrder.issueId);
                setAssetId(workOrder.assetId);
                workOrder.statusId = (workOrder.statusId ?? "").toString();
                setOtherInfo(
                  JSON.parse(workOrder.otherInfo ? workOrder.otherInfo : "{}")
                );
                return workOrder;
              }}
              newAction={async (abortController, newWorkOrder) => {
                const newWorkOrderResponse = await createWorkOrder(
                  abortController,
                  issueId,
                  {
                    assetId: assetId,
                    zoneId: zoneId,
                    description: newWorkOrder.description,
                    issueTypeId: newWorkOrder.issueTypeId,
                    otherInfo: JSON.stringify(otherInfo),
                  }
                );
                setSuccessMessage(
                  `WorkOrder with id: ${newWorkOrderResponse.workOrderId} created.`
                );
              }}
              editAction={async (abortController, id, editedWorkOrder) => {
                await updateWorkOrder(abortController, id, {
                  description: editedWorkOrder.description,
                  statusId: Number.parseInt(editedWorkOrder.statusId),
                  otherInfo: JSON.stringify(otherInfo),
                });
                setSuccessMessage(
                  `WorkOrder with id: ${id} successfully updated.`
                );
              }}
              isSaved={isSaved}
              back={"/workOrders"}
              callGetBeforeNew={true}
              metadata={{
                fields: [
                  {
                    name: "statusId",
                    fieldType: "Lookup",
                    label: "Status Id",
                    lookupList: "Statuses",
                  },
                  {
                    name: "description",
                    fieldType: "String",
                    label: "Description",
                    rows: 5,
                  },
                ],
                lookups: [
                  {
                    name: "Statuses",
                    values: workOrderStatuses?.map((wos) => ({
                      key: wos.id.toString(),
                      value: wos.name,
                    })),
                  },
                ],
                validations: [],
              }}
            >
              {metadata ? (
                <OtherInfo
                  metadata={metadata}
                  otherInfo={JSON.stringify(otherInfo)}
                  isSaved={isSaved}
                  onOtherInfoChanged={(newValue: string) =>
                    setOtherInfo(newValue)
                  }
                />
              ) : (
                <></>
              )}
            </EditItem>
          </Stack>
        </PivotItem>
        <PivotItem headerText="Comments">
          <WorkOrderComments workOrderId={workOrderId} />
        </PivotItem>
        <PivotItem headerText="Location">
          <WorkOrderMap workOrderId={workOrderId} />
        </PivotItem>
      </Pivot>
    </>
  );
};

export default WorkOrder;
