import { CommandBar, DetailsListLayoutMode, FontIcon, IColumn, ICommandBarItemProps, mergeStyleSets, Pivot, PivotItem, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { appRoles } from '../../authConfig';
import { scrollStackItemStyles } from '../../common/styles/StackStyles';
import { getAssetStatuses, getWorkOrderStatuses, IAssetStatus, IStatus, IWorkOrderStatus } from '../../services/assetServices';
import AppContext from '../AppContext';
import { useAtomValue, useSetAtom } from 'jotai';
import { errorMessageAtom, isInProgressAtom } from '../../atoms/messageBarAtoms';
import { profileDataAtom } from '../../atoms/authAtoms';
import { Selection } from "@fluentui/react/lib/DetailsList";

export interface IStatuses {
  entityType: string;
}

const Statuses = (props: IStatuses) => {
  const context = useContext(AppContext);
  const [selectedStatus, setSelectedStatus] = useState<IStatus>();
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom); const profileData = useAtomValue(profileDataAtom);

  const navigate = useNavigate();

  const [shimmered, setShimmered] = useState(false);
  const [workOrderStatuses, setWorkOrderStatuses] = useState<IWorkOrderStatus[]>([]);
  const [assetStatuses, setAssetStatuses] = useState<IAssetStatus[]>([]);

  const fetchData = async () => {
    setIsInProgress(true);
    const abortController = new AbortController();
    try {
      if (props.entityType === 'workOrder') {
        context.setSelectedTab('WorkOrders statuses');
        const data: IWorkOrderStatus[] = await getWorkOrderStatuses(abortController);

        setWorkOrderStatuses(data);
        setShimmered(false);
      } else if (props.entityType === 'asset') {
        context.setSelectedTab('Asset statuses');

        const data: IAssetStatus[] = await getAssetStatuses(abortController);

        setAssetStatuses(data);
        setShimmered(false);
      }
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
    finally {
      setIsInProgress(false);
    }

    return () => {
      abortController.abort();
    }
  }

  useEffect(() => {
    fetchData();
  }, [props.entityType]);

  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "code",
      name: "Code",
      fieldName: "code",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "icon",
      name: "Icon",
      fieldName: "icon",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "color",
      name: "Color",
      fieldName: "color",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  const workOrderCellClass = mergeStyleSets({
    default: {
      padding: '5px',
    },
    bar: {
      height: '24px', display: 'block',
    }
  });

  const _renderItemColumn = (
    item: IWorkOrderStatus,
    index?: number,
    column?: IColumn
  ) => {
    const fieldContent = item[column?.fieldName as keyof IWorkOrderStatus] as string;

    switch (column?.key) {
      case "color":
        return item.color ? (
          <span
            className={workOrderCellClass.bar}
            style={{ backgroundColor: `${item.color}` }}
          ></span>
        ) : (
          <span></span>
        );
      case "icon":
        return item.icon ? (
          <FontIcon iconName={item?.icon} style={{ width: 25, height: 25, fontSize: 25 }} />
        ) : (
          <span></span>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };

  const _items: ICommandBarItemProps[] = [
    {
      key: "newStatus",
      text: "New",
      iconProps: { iconName: "Add" },
      disabled: !profileData.roles.includes(appRoles.Admin),
      onClick: () => {
        navigate('/newStatuses/' + props.entityType);
      },
    },
    {
      key: "editStatuse",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      disabled: !profileData.roles.includes(appRoles.Admin),
      onClick: () => {
        if (selectedStatus) {
          navigate(`/editStatuses/${props.entityType}?id=${selectedStatus.id}`);
        }
      },
    },
  ];

  const _selection = new Selection({
    onSelectionChanged: () => {
      const sel = _getSelectionDetails();
      if (sel.item) {
        setSelectedStatus(sel.item);
      }
    },
  });

  const _getSelectionDetails = () => {
    const selectionCount = _selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return { count: 0, message: "No items selected" };
      case 1: {
        const firstSelection = _selection.getSelection()[0] as IStatus;
        return {
          count: 1,
          id: firstSelection.id,
          item: firstSelection,
        };
      }
      default:
        return {
          count: selectionCount,
          message: `${selectionCount} items selected`,
        };
    }
  };

  return (
    <>
      <Stack styles={{ root: { height: 450, width: 800 } }}>
        <CommandBar
          items={_items}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <Stack>
          <Stack.Item styles={scrollStackItemStyles}>
            <ShimmeredDetailsList
              items={props.entityType === 'workOrder' ? workOrderStatuses : assetStatuses}
              columns={_columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              onRenderItemColumn={_renderItemColumn}
              selectionMode={SelectionMode.single}
              selectionPreservedOnEmptyClick={true}
              selection={_selection}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={shimmered}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
}

export default Statuses