import {
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  IContextualMenuProps,
  IIconProps,
  Link,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from "@fluentui/react";
import {
  ConstrainMode,
  DetailsList,
  Selection,
} from "@fluentui/react/lib/DetailsList";
import {
  IIssue,
  IWorkOrder,
  IWorkOrderStatus,
  updateStatusOfWorkOrder,
} from "../../services/assetServices";
import { useNavigate } from "react-router-dom";
import {
  focusZoneProps,
  gridStyles,
  gridStylesNames,
} from "../../common/styles/GridStyles";
import { stackStyle, scrollStackItemStyles } from "../../common/styles/StackStyles";

interface IWorkItemProps {
  issueWorkOrders: IWorkOrder[];
  workOrderStatuses?: IWorkOrderStatus[];
  issue?: IIssue;
  shimmered: boolean;
  listSelectionMode?: SelectionMode;
  selection?: IWorkOrder[];
  setSelection?: React.Dispatch<React.SetStateAction<IWorkOrder[]>>;
  showRemoveColumn?: boolean;
  showStatusChangeMenu?: boolean;
  onRemoveButtonClicked?: (workOrder: IWorkOrder) => void;
}

const WorkItems = (props: IWorkItemProps) => {
  const navigate = useNavigate();

  const allColumns = [
    {
      key: "Remove",
      name: "",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 50,
      isResizable: true,
      onRender: (item: any) => (
        <IconButton
          iconProps={{ iconName: "Delete" }}
          onClick={() => {
            if (props.onRemoveButtonClicked) {
              const workOrder = props.issueWorkOrders.find(
                (wo) => wo.id === item.id
              );
              if (workOrder) {
                props.onRemoveButtonClicked(workOrder);
              }
            }
          }}
        />
      ),
    },
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 40,
      isResizable: true,
    },
    {
      key: "Code",
      name: "Code",
      fieldName: "code",
      minWidth: 75,
      maxWidth: 85,
      isResizable: true,
    },
    {
      key: "Description",
      name: "Description",
      fieldName: "description",
      minWidth: 200,
      maxWidth: 275,
      isResizable: true,
    },
    {
      key: "Status",
      name: "Status",
      fieldName: "",
      minWidth: 100,
      maxWidth: 100,
      isResizable: false,
    },
    {
      key: "Category",
      name: "Category",
      fieldName: "assetCategory",
      minWidth: 150,
      maxWidth: 150,
      isResizable: false,
    },
    {
      key: "Zone",
      name: "Zone",
      fieldName: "zoneName",
      minWidth: 150,
      maxWidth: 150,
      isResizable: false,
    },
    {
      key: "IssueId",
      name: "Issue Id",
      fieldName: "issueId",
      minWidth: 50,
      maxWidth: 75,
      isResizable: true,
      onRender: (item: any) => (
        <Link onClick={() => navigate(`/editIssue?id=${item.id}`)}>
          {item.id}
        </Link>
      ),
    },
    {
      key: "createdAt",
      name: "Created at",
      fieldName: "createdAt",
      minWidth: 125,
      maxWidth: 155,
      isResizable: true,
    },
  ];

  const _columns = props.showRemoveColumn
    ? allColumns
    : allColumns.filter((c) => c.key !== "Remove");

  const menuItemClicked = (ev: any, item: any) => {
    const key = item.key as string;
    const keyParts = key.split("_");
    const action = keyParts[0];
    const wosId = keyParts[1];
    const code = keyParts[2];
    const abortController = new AbortController();

    updateStatusOfWorkOrder(
      abortController,
      code,
      action === "INPRG" ? "Assigned" : action === "CLSED" ? "Resolved" : "",
      Number.parseInt(wosId),
      `Some Description ${code}`
    );
  };

  const changeStateIcon: IIconProps = { iconName: "ForwardEvent" };

  const _renderWorkOrdersItemColumn = (
    item: IWorkOrder,
    index?: number,
    column?: IColumn
  ) => {
    const fieldContent = item[column?.fieldName as keyof IWorkOrder] as string;
    const itemWos = props.workOrderStatuses?.find(
      (wos) => wos.id === item.statusId
    );
    const itemColor = itemWos?.color;

    switch (column?.key) {
      case "Status":
        return props.showStatusChangeMenu ? (
          <Stack
            horizontal
            style={{
              borderBottomColor: `${itemColor}`,
              borderBlockStyle: "solid",
              borderTopStyle: "none",
            }}
          >
            <Stack.Item align="start">
              <span style={{ display: "flex", justifyContent: "left" }}>
                {itemWos?.code}
              </span>
            </Stack.Item>
            <Stack.Item align="end">
              <div style={{ display: "flex", justifyContent: "right" }}>
                <IconButton
                  // style={{ display: 'contents' }}
                  menuProps={_getMenuProps(item)}
                  iconProps={changeStateIcon}
                  title="Action"
                />
              </div>
            </Stack.Item>
          </Stack>
        ) : (
          <span style={{ display: "flex", justifyContent: "left" }}>
            {itemWos?.code}
          </span>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };

  const _menuProps: IContextualMenuProps = {
    items: props.workOrderStatuses
      ? props.workOrderStatuses?.map((wos) => ({
          key: `${wos.code}_${wos.id}`,
          text: wos.name,
          iconProps: { iconName: wos.icon },
          onClick: menuItemClicked,
        }))
      : [],
    // items: [
    //   {
    //     key: "Scheduled",
    //     text: "Field Staff Scheduled",
    //     iconProps: { iconName: "Calendar" },
    //     onClick: menuItemClicked,
    //   },
    //   {
    //     key: "Closed",
    //     text: "Closed by Field Staff",
    //     iconProps: { iconName: "ChromeClose" },
    //     onClick: menuItemClicked,
    //   },
    // ],
    directionalHintFixed: true,
  };

  const _getMenuProps = (wo: IWorkOrder): IContextualMenuProps => {
    return {
      ..._menuProps,
      items: _menuProps.items?.map((mp: any) => {
        return { ...mp, key: `${mp.key}_${wo.code}` };
        // return {
        //   text: mp.text,
        //   iconProps: mp.iconProps,
        //   onClick: mp.onClick,
        //   key: `${mp.key}_${item.id}`,
        // };
      }),
    };
  };

  const _selection = new Selection({
    onSelectionChanged: () => {
      if (props.setSelection) {
        props.setSelection(
          _selection.getSelection()?.map((item) => item as IWorkOrder)
        );
      }
    },
  });

  return props.issueWorkOrders ? (
    <Stack styles={stackStyle}>
      <Stack.Item align="start" styles={scrollStackItemStyles}>
        <DetailsList
          items={props.issueWorkOrders}
          columns={_columns}
          setKey="set"
          selectionMode={props.listSelectionMode ?? SelectionMode.none}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          constrainMode={ConstrainMode.unconstrained}
          onRenderItemColumn={_renderWorkOrdersItemColumn}
          selection={_selection}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="select row"
          styles={gridStyles}
          focusZoneProps={focusZoneProps}
          selectionZoneProps={{
            className: gridStylesNames.selectionZone,
          }}
        />
      </Stack.Item>
    </Stack>
  ) : (
    <></>
  );
};

export default WorkItems;
