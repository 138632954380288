import { useEffect, useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import {
  ICreateReportRequest,
  IMetadata,
  IUpdateReportRequest,
  IGroup,
  createReport,
  editReport,
  getMetadata,
  getReport,
} from "../../services/assetServices";
import { useSetAtom } from "jotai";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import {
  Stack,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useLocation } from "react-router-dom";
import OtherInfo from "../../common/OtherInfo";
import UserOrGroupSelector from "../../common/selectors/UsersOrGroupSelectors";

const Report = () => {
  const query = new URLSearchParams(useLocation().search);
  const itemId = Number.parseInt(query.get("id") ?? "");

  const [reportId, setReportId] = useState<number>(itemId);
  const [group, setGroup] = useState<IGroup>();

  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const [
    isUserSelectorOpen,
    { setTrue: showUserSelector, setFalse: hideUserSelector },
  ] = useBoolean(false);

  const isSaved = useRef(false);

  return (
    <>
      <EditItem
        getAction={async (abortController, id) => {
          const report: any = await getReport(abortController, id);
          setReportId(id);
          report.statusId = (report.statusId ?? "").toString();
          return report;
        }}
        newAction={async (abortController, newReport) => {
          const data: ICreateReportRequest = {
            type: newReport.type,
            title: newReport.title,
            source: newReport.source,
          };
          const newReportResponse = await createReport(
            abortController,
            data
          );
          setSuccessMessage(
            `Report with id: ${newReportResponse.reportId} created.`
          );
        }}
        editAction={async (abortController, id, editedReport) => {
          const data: IUpdateReportRequest = {
            type: editedReport.type,
            title: editedReport.title,
            source: editedReport.source,
          };
          await editReport(abortController, id, data);
          setSuccessMessage(`Report with id: ${id} successfully updated.`);
        }}
        isSaved={isSaved}
        back={"/settings/reports"}
        metadata={{
          fields: [
            {
              name: "type",
              fieldType: "String",
              label: "Type",
            },
            {
              name: "title",
              fieldType: "String",
              label: "Title",
            },
            {
              name: "source",
              fieldType: "String",
              label: "Source",
            },
          ],
          lookups: [],
          validations: [],
        }}
      >
      </EditItem>
    </>
  );
};

export default Report;
