import { atom } from "jotai";
import { ITenant } from "../services/assetServices";

export const tenantAtom = atom<ITenant | undefined>(undefined);

export const tenantInfoAtom = atom<any>(
    (get) => {
        const otherInfo = get(tenantAtom)?.otherInfo;
        return otherInfo ? JSON.parse(otherInfo) : undefined; 
    }
)
