import { Dropdown, Stack } from '@fluentui/react';
import { useContext, useEffect, useRef, useState } from 'react'
import { getReports, IReport } from '../../services/assetServices';
import AppContext from '../AppContext';
import { useSetAtom } from 'jotai';
import { errorMessageAtom, isInProgressAtom } from '../../atoms/messageBarAtoms';
import AssetsPanel from '../dashboard/AssetsPanel';
import ContractorsPanel from '../dashboard/ContractorsPanel';

const ReportsViewer = () => {
  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const [reports, setReports] = useState<IReport[]>();
  const [report, setReport] = useState<IReport>();

  const fetchData = async () => {

    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      const reports = await getReports(abortController);
      setReports(reports);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    }
  }

  useEffect(() => {
    context.setSelectedTab("Reports");
    fetchData()
  }, []);

  return (
    <Stack>
      <Dropdown style={{ width: 200 }}
        options={reports?.map(r => ({ key: r.title, text: r.title, data: r })) ?? []}
        onChange={(e, option) => {
          setReport(option?.data);
        }}
      />
      {report?.type != "Internal" ?
        <iframe title={report?.title} width="1140" height="541.25" src={report?.source} allowFullScreen={true} />
        :
        report?.source == "Assets" ?
          <AssetsPanel /> :
          report?.source == "Contractors" ?
          <ContractorsPanel /> :
          null
      }
    </Stack>
  )
}

export default ReportsViewer