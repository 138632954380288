import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import {
  IVerticalBarChartDataPoint,
  VerticalBarChart,
} from "@fluentui/react-charting";
import { useSetAtom } from "jotai";
import {
  errorMessageAtom,
  isInProgressAtom,
} from "../../atoms/messageBarAtoms";
import { IChartData } from "../../services/assetServices";
import {
  Dropdown,
  IStackItemStyles,
  IStackTokens,
  Stack,
  Text,
} from "@fluentui/react";

interface IChartPanelProps {
  getDashboardPanelData(abortController: AbortController, options: string): Promise<IChartData>;
  getOptions(): { key: string; text: string }[];
}

export interface IChartPanelRef {
  Resize: (width: number, height: number) => void;
}

const ChartPanel: React.ForwardRefRenderFunction<
  IChartPanelRef,
  IChartPanelProps
> = (props, ref) => {
  const [width, setWidth] = useState<number>(400);
  const [height, setHeight] = useState<number>(240);

  const Resize = (newWidth: number, newHeight: number) => {
    setWidth(newWidth);
    setHeight(newHeight);
  };

  useImperativeHandle(ref, () => ({
    Resize,
  }));

  const [data, setData] = useState<IChartData>();
  const [chartPoints, setChartPoints] = useState<IVerticalBarChartDataPoint[]>(
    []
  );
  const [by, setBy] = useState(props.getOptions()[0].key);

  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const fetchData = async () => {
    setIsInProgress(true);
    const abortController = new AbortController();

    try {
      const data: IChartData = await props.getDashboardPanelData(abortController, by);
      const chartData = Object.entries(data.values ?? []).map(
        ([name, value]) => ({
          x: name,
          y: value,
        })
      );
      setChartPoints(chartData ?? []);
      setData(data);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, [by]);

  const assetPanelChart = (): JSX.Element => {
    const rootStyle = {
      width: `${width}px`,
      height: `${height}px`,
    };

    const itemAlignmentsStackTokens: IStackTokens = {
      childrenGap: 5,
      padding: 10,
    };

    const stackItemStyles: IStackItemStyles = {
      root: {
        display: 'flex',
        justifyContent: 'right',
      },
    };
    
    return data ? (
      <>
        <Stack tokens={itemAlignmentsStackTokens} horizontal>
          <Stack.Item>
            <Text variant="xLarge">Chart</Text>
          </Stack.Item>
          <Stack.Item grow styles={stackItemStyles}>
            <Dropdown
              style={{ width: 120 }}
              options={props.getOptions()}
              selectedKey={by}
              onChange={(_, option) => {
                setBy(option?.key?.toString() ?? "");
              }}
            />
          </Stack.Item>
        </Stack>
        <div style={rootStyle}>
          <VerticalBarChart
            data={chartPoints}
            strokeWidth={4}
            yMaxValue={5}
            hideLegend={true}
            height={height}
            width={width}
          />
        </div>
      </>
    ) : (
      <>Loading ... </>
    );
  };

  return <div>{assetPanelChart()}</div>;
};

export default forwardRef(ChartPanel);
