import { appRoles } from "../../authConfig";
import PagedList, { IPagedListRefType } from "../../common/PagedList";
import { deleteReport, getReports } from "../../services/assetServices";
import { useAtomValue, useSetAtom } from "jotai";
import { profileDataAtom } from "../../atoms/authAtoms";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { successMessageAtom } from "../../atoms/messageBarAtoms";

const Reports = () => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const [selectedReportId, setSelectedReportId] = useState<Number>();

  const pagedListRef = useRef<IPagedListRefType>(null);

  return (
    <PagedList
      selectedTab="Reports"
      ref={pagedListRef}
      columns={[
        { key: "type" },
        { key: "title", minWidth: 200, },
        { key: "source" },
      ] as any}
      getAction={async (abortController: AbortController, pageSize: number, pageNo: number) => {
        const reports = await getReports(abortController);
        return { items: reports, rowsCount: reports.length, pageNo: 1, pageSize: 10, pageCount: 1, isLastPage: true };
      }}
      commandBarItems={[
        {
          key: "newReport",
          text: "New",
          iconProps: { iconName: "Add" },
          onClick: () => navigate("/newReport"),
          disabled: !profileData.roles.includes(appRoles.Admin),
        },
        {
          key: "editReport",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: () => navigate(`/editReport?id=${selectedReportId}`),
          disabled:
            !profileData.roles.includes(appRoles.Admin) || !selectedReportId,
        },
        {
          key: "deleteReport",
          text: "Delete",
          iconProps: { iconName: "Delete" },
          onClick: () => {
            if (selectedReportId) {
              const abortController = new AbortController();
              deleteReport(abortController, Number(selectedReportId)).then(() => {
                pagedListRef.current?.refresh();
                setSuccessMessage(`Report with id: ${selectedReportId} deleted.`);
              });
            }
          },
          disabled:
            !profileData.roles.includes(appRoles.Admin) || !selectedReportId,
        },
      ]}
      onSelectionChange={(selection: any) => {
        setSelectedReportId(Number(selection.id));
      }}
      onRowDoubleClicked={(selection: any) =>
        navigate(`/editReport?id=${selection.id}`)
      }
    ></PagedList>
  );
};

export default Reports;
