import { useEffect, useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import {
  ICreateRentRequest,
  IMetadata,
  IUpdateRentRequest,
  createRent,
  editRent,
  getRent,
  getMetadata,
  IRent,
  IAsset,
  ITrackingDevice,
  IDateTimeRange,
  ITimeslot,
  getRenter,
} from "../../services/assetServices";
import { useSetAtom } from "jotai";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import {
  DatePicker,
  IconButton,
  Label,
  Pivot,
  PivotItem,
  Stack,
  TextField,
  TimePicker,
} from "@fluentui/react";
import { useLocation } from "react-router-dom";
import OtherInfo from "../../common/OtherInfo";
import {
  labelColumnStyle,
  valueColumnStyle,
} from "../../common/styles/FormsStyles";
import AssetSelector from "../workOrders/AssetSelector";
import RentMap from "./RentMap";
import TrackingDeviceSelector from "../../common/selectors/TrackingDeviceSelector";
import TimeslotSelector from "./TimeslotSelector";
import { useBoolean } from "@fluentui/react-hooks";

const Rent = () => {
  const query = new URLSearchParams(useLocation().search);
  const itemId = Number.parseInt(query.get("id") ?? "");
  const renterId = Number.parseInt(query.get("renterId") ?? "");

  const [metadata, setMetadata] = useState<IMetadata>();
  const [otherInfo, setOtherInfo] = useState({});

  const [rentId, setRentId] = useState<number>(itemId);
  const [renterName, setRenterName] = useState<string>();

  const [isRentLoaded, setIsRentLoaded] = useState<boolean>(false);

  const setSuccessMessage = useSetAtom(successMessageAtom);

  const [assetId, setAssetId] = useState<number>();
  const [assetName, setAssetName] = useState<string>();

  const [startTime, setStartTime] = useState<Date>();
  const [endTime, setEndTime] = useState<Date>();

  const [trackingDeviceId, setTrackingDeviceId] = useState<number>();
  const [trackingDeviceName, setTrackingDeviceName] = useState<string>();

  const [
    isTimeslotSelectorOpen,
    { setTrue: showTimeslotSelector, setFalse: hideTimeslotSelector },
  ] = useBoolean(false);
  const [selectedTimeslot, setSelectedTimeslot] = useState<IDateTimeRange>({
    startTime: new Date(),
    endTime: new Date(),
  });

  const isSaved = useRef(false);

  const handleCancelTracking = () => {
    setTrackingDeviceId(-1);
    setTrackingDeviceName(undefined);
  };

  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const fetchData = async () => {
    const abortController = new AbortController();
    setIsInProgress(true);

    try {
      const abortController = new AbortController();

      const metadata = await getMetadata(abortController, 'Rent');
      setMetadata(metadata);

      const renter = await getRenter(abortController, renterId);
      setRenterName(renter.name);

      if (rentId > 0) {
        const rent: IRent = await getRent(abortController, rentId);
        setAssetId(rent.assetId);
        setAssetName(rent.assetName);
        setTrackingDeviceId(rent.trackingDeviceId);
        setTrackingDeviceName(rent.trackingDeviceName);
        setSelectedTimeslot({
          startTime: new Date(rent.fromDate.toLocaleString() + "Z"),
          endTime: new Date(rent.toDate.toLocaleString() + "Z"),
        });

        setStartTime(new Date(rent.fromDate.toLocaleString() + "Z"));
        setEndTime(new Date(rent.toDate.toLocaleString() + "Z"));

        setOtherInfo(JSON.parse(rent.otherInfo));
        setIsRentLoaded(true);
      } else {
        setIsRentLoaded(true);
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Pivot>
        <PivotItem headerText="Details">
          {isRentLoaded ? (
            <Stack tokens={{ childrenGap: 5 }}>
              {/* Renter */}
              <Stack horizontal>
                <Label style={labelColumnStyle}>Renter</Label>
                <TextField
                  style={{ width: valueColumnStyle.width - 2 }}
                  value={renterName}
                  readOnly
                  borderless
                />
              </Stack>
              {/* Asset Selector */}
              <AssetSelector
                disabled={isSaved.current}
                onSelected={(asset: IAsset) => {
                  setAssetId(asset.id);
                  setAssetName(asset.name);
                }}
                assetName={assetName}
              />
              {/* Tracking device selector */}
              <Stack horizontal>
                <TrackingDeviceSelector
                  disabled={isSaved.current}
                  onSelected={(trackingDevice: ITrackingDevice) => {
                    setTrackingDeviceId(trackingDevice.id);
                    setTrackingDeviceName(
                      trackingDevice.code +
                      (!trackingDevice.name ? "" : "|" + trackingDevice.name)
                    );
                  }}
                  trackingDeviceName={trackingDeviceName}
                />
                <IconButton
                  iconProps={{ iconName: "Cancel" }}
                  onClick={handleCancelTracking}
                />
              </Stack>
              {/* From date selector */}
              <Stack horizontal>
                <Label style={labelColumnStyle}>From date</Label>
                <Stack
                  horizontal
                  style={valueColumnStyle}
                  tokens={{ childrenGap: 5 }}
                >
                  <DatePicker
                    styles={{
                      root: { height: 32, width: 150 },
                      textField: { width: 150 },
                    }}
                    placeholder={rentId ? new Date(
                      selectedTimeslot?.startTime
                    ).toDateString() : undefined}
                    ariaLabel="Select a date"
                    isMonthPickerVisible={false}
                    onSelectDate={(selectedDate: Date | null | undefined) => {
                      if (selectedDate) {
                        setSelectedTimeslot({
                          startTime: selectedDate,
                          endTime: selectedTimeslot?.endTime,
                        });
                      }
                    }}
                  />
                  <TimePicker
                    defaultValue={rentId ? startTime : undefined}
                    onChange={(_, time) => {
                      const newStartTime = new Date(selectedTimeslot.startTime);
                      newStartTime.setHours(time.getHours());
                      newStartTime.setMinutes(time.getMinutes());
                      newStartTime.setSeconds(0);
                      setSelectedTimeslot({
                        startTime: newStartTime,
                        endTime: selectedTimeslot?.endTime,
                      });
                    }}
                  />
                </Stack>
              </Stack>
              {/* To date selector */}
              <Stack horizontal>
                <Label style={labelColumnStyle}>To date</Label>
                <Stack
                  horizontal
                  style={valueColumnStyle}
                  tokens={{ childrenGap: 5 }}
                >
                  <DatePicker
                    styles={{
                      root: { height: 32, width: 150 },
                      textField: { width: 150 },
                    }}
                    placeholder={rentId ? new Date(
                      selectedTimeslot?.endTime
                    ).toDateString() : undefined}
                    ariaLabel="Select a date"
                    isMonthPickerVisible={false}
                    onSelectDate={(selectedDate: Date | null | undefined) => {
                      if (selectedDate) {
                        setSelectedTimeslot({
                          startTime: selectedTimeslot?.startTime,
                          endTime: selectedDate,
                        });
                      }
                    }}
                  />
                  <TimePicker
                    defaultValue={rentId ? endTime : undefined}
                    onChange={(_, time) => {
                      const newEndTime = new Date(selectedTimeslot.endTime);
                      newEndTime.setHours(time.getHours());
                      newEndTime.setMinutes(time.getMinutes());
                      newEndTime.setSeconds(0);
                      setSelectedTimeslot({
                        startTime: selectedTimeslot?.startTime,
                        endTime: newEndTime,
                      });
                    }}
                  />
                </Stack>
              </Stack>
              <EditItem
                preButtons={[
                  {
                    text: "Time",
                    onClick: function (): void {
                      showTimeslotSelector();
                    },
                  },
                ]}
                getAction={async (abortController, id) => {
                  const rent: IRent = await getRent(abortController, id);
                  setRentId(id);
                  setAssetName(rent.assetName);
                  setRenterName(rent.renterName);
                  setTrackingDeviceName(rent.trackingDeviceName);
                  setAssetId(rent.assetId);
                  setOtherInfo(JSON.parse(rent.otherInfo));
                  rent.statusId = rent.statusId;
                  return rent;
                }}
                newAction={async (abortController, newRent) => {
                  const data: ICreateRentRequest = {
                    assetId: assetId ?? 0,
                    renterId: renterId,
                    trackingDeviceId: trackingDeviceId,
                    code: newRent.code,
                    description: newRent.description,
                    otherInfo: JSON.stringify(otherInfo),
                    agreedPrice: newRent.agreedPrice,
                    agreedPriceUnit: newRent.agreedPriceUnit,
                    fromDate: selectedTimeslot?.startTime ?? newRent.fromDate,
                    toDate: selectedTimeslot?.endTime ?? newRent.toDate,
                    statusId: Number.parseInt(newRent.statusId),
                    status: newRent.statusId,
                  };
                  const newRentResponse = await createRent(abortController, data);
                  setSuccessMessage(
                    `Rent with id: ${newRentResponse.rentId} created.`
                  );
                }}
                editAction={async (abortController, id, editedRent) => {
                  const data: IUpdateRentRequest = {
                    assetId: assetId ?? 0,
                    renterId: renterId,
                    description: editedRent.description,
                    trackingDeviceId: trackingDeviceId,
                    code: editedRent.code,
                    otherInfo: JSON.stringify(otherInfo),
                    agreedPrice: editedRent.agreedPrice,
                    agreedPriceUnit: editedRent.agreedPriceUnit,
                    fromDate: selectedTimeslot?.startTime ?? editedRent.fromDate,
                    toDate: selectedTimeslot?.endTime ?? editedRent.toDate,
                    statusId: Number.parseInt(editedRent.statusId),
                    status: editedRent.status,
                  };
                  await editRent(abortController, id, data);
                  setSuccessMessage(`Rent with id: ${id} successfully updated.`);
                }}
                isSaved={isSaved}
                back={`/rents?id=${renterId}`}
                metadata={{
                  fields: [
                    {
                      name: "description",
                      fieldType: "String",
                      label: "Description",
                    },
                    {
                      name: "code",
                      fieldType: "String",
                      label: "Code",
                    },
                    {
                      name: "agreedPrice",
                      fieldType: "Number",
                      label: "Agreed Price",
                    },
                    {
                      name: "agreedPriceUnit",
                      fieldType: "String",
                      label: "Price Unit",
                    },
                    {
                      name: "statusId",
                      fieldType: "Lookup",
                      label: "Status Id",
                      lookupList: "RentStatuses",
                    },
                    {
                      name: "status",
                      fieldType: "String",
                      label: "Status",
                    },
                  ],
                  lookups: [
                    {
                      name: "RentStatuses",
                      values: [
                        {
                          key: "1",
                          value: "Pending",
                        },
                        {
                          key: "2",
                          value: "Rented",
                        },
                        {
                          key: "3",
                          value: "Returned",
                        },
                        {
                          key: "4",
                          value: "Canceled",
                        },
                      ],
                    },
                  ],
                  validations: [],
                }}
              >
                <>
                  {metadata ? (
                    <OtherInfo
                      metadata={metadata}
                      otherInfo={JSON.stringify(otherInfo)}
                      isSaved={isSaved}
                      onOtherInfoChanged={(newValue: string) =>
                        setOtherInfo(newValue)
                      }
                    />
                  ) : (
                    <></>
                  )}
                </>
              </EditItem>
            </Stack>
          ) : null}
        </PivotItem>
        <PivotItem headerText="Location">
          <RentMap rentId={rentId} />
        </PivotItem>
      </Pivot>
      {assetId !== undefined && assetId !== 0 && (
        <TimeslotSelector
          assetId={assetId ?? 0}
          isOpen={isTimeslotSelectorOpen}
          hideTimeslotSelector={hideTimeslotSelector}
          onTimeslotSelected={(selectedTimeslot: ITimeslot) => {
            if (selectedTimeslot.rentId !== null) {
              return;
            }
            setSelectedTimeslot(selectedTimeslot);
            hideTimeslotSelector();
          }}
        />
      )}
    </>
  );
};

export default Rent;
