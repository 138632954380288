import {
  IStackStyles,
  ITextFieldStyles,
  Pivot,
  PivotItem,
  Stack,
  TextField,
} from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSettings, ISettings } from "../../services/assetServices";
import AppContext from "../AppContext";
import References from "./References";
import Statuses from "./Statuses";
import Reports from "./Reports";

const Settings = () => {
  let { tab } = useParams();

  const navigate = useNavigate();

  const [clientUrl, setClientUrl] = useState("");

  const context = useContext(AppContext);

  useEffect(() => {
    context.setSelectedTab("Settings");
    const abortController = new AbortController();

    getSettings(abortController).then((data: ISettings) => {
      setClientUrl(data?.clientUrl);
    });

    return () => {
      abortController.abort();
    };
  }, []);

  const textFieldStyles: Partial<ITextFieldStyles> = {
    fieldGroup: { width: 300 },
    root: { padding: 10 },
  };

  const stackStyles: IStackStyles = {
    root: {
      label: {
        display: "flex",
        paddingLeft: 10,
      },
    },
  };

  return (
    <Stack styles={stackStyles} horizontalAlign="stretch">
      <Pivot
        defaultSelectedKey={tab ?? "client"}
        onLinkClick={(item) => {
          if (item?.props.itemKey === "back") {
            navigate("/");
          } else {
            navigate(`/settings/${item?.props.itemKey}`);
          }
        }}
      >
        <PivotItem itemIcon="ChromeBack" itemKey="back" />
        <PivotItem headerText="Client" itemKey="client">
          <TextField
            label="ClientUrl"
            value={clientUrl}
            styles={textFieldStyles}
            readOnly
          />
        </PivotItem>
        <PivotItem headerText="References" itemKey="references">
          <References />
        </PivotItem>
        <PivotItem headerText="WorkOrder Statuses" itemKey="workOrderStatuses">
          <Statuses entityType="workOrder"/>
        </PivotItem>
        <PivotItem headerText="Asset Statuses" itemKey="assetStatuses">
          <Statuses entityType="asset" />
        </PivotItem>
        <PivotItem headerText="Reports" itemKey="reports">
          <Reports />
        </PivotItem>
        <PivotItem headerText="Other" itemKey="other"></PivotItem>
      </Pivot>
    </Stack>
  );
};

export default Settings;
