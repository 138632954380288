import { useRef } from "react";
import EditItem from "../../common/EditItem";
import { getWorkflowExec, IWorkflowExec } from "../../services/assetServices";

const WorkflowExec = () => {
  const isSaved = useRef(false);

  return (
    <>
      <div>WorkflowExec</div>
      <EditItem
        getAction={async (abortController, id) => {
          const workflowExec: IWorkflowExec = await getWorkflowExec(abortController, id);
          return workflowExec;
        }}
        newAction={async (abortController, newWorkflowExec) => { }}
        editAction={async (abortController, id, editedWorkflowExec) => { }}
        isSaved={isSaved}
        back={"/workflows/workflowExecs"}
        metadata={{
          fields: [
            {
              name: "id",
              fieldType: "Number",
              label: "Id",
              readonly: true,
            },
            {
              name: "uuid",
              fieldType: "String",
              label: "UUID",
              readonly: true,
            },
            {
              name: "workflowId",
              fieldType: "Number",
              label: "Workflow Id",
              readonly: true,
              options: {showAsLink: true, linkUrl: "/editWorkflow?id={id}"},
            },
            {
              name: "entityType",
              fieldType: "String",
              label: "Entity Type",
              readonly: true,
            },
            {
              name: "entityId",
              fieldType: "Number",
              label: "Entity Id",
              readonly: true,
            },
            {
              name: "triggeredAt",
              fieldType: "Date",
              options: { showTime: true },
              label: "Triggered at",
              readonly: true,
            },
            {
              name: "completedAt",
              fieldType: "Date",
              options: { showTime: true },
              label: "Completed at",
              readonly: true,
            },
            {
              name: "workflowUrl",
              fieldType: "String",
              label: "Workflow Url",
              readonly: true,
            },
            {
              name: "status",
              fieldType: "String",
              label: "Status",
              readonly: true,
            },
            {
              name: "description",
              fieldType: "String",
              label: "Description",
              rows: 5,
              readonly: true,
            },
          ],
          lookups: [],
          validations: [],
        }}
      />
    </>
  );
};

export default WorkflowExec;
