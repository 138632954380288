import { appRoles } from "../../authConfig";
import PagedList, { IPagedListRefType } from "../../common/PagedList";
import { deleteWorkflow, getWorkflows } from "../../services/assetServices";
import { useAtomValue, useSetAtom } from "jotai";
import { profileDataAtom } from "../../atoms/authAtoms";
import { useNavigate, useParams } from "react-router-dom";
import { useRef, useState } from "react";
import { successMessageAtom } from "../../atoms/messageBarAtoms";
import { Pivot, PivotItem } from "@fluentui/react";
import WorkflowExecs from "./WorkflowExecs";

const Workflows = () => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();
  let { tab } = useParams();

  const setSuccessMessage = useSetAtom(successMessageAtom);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<Number>();

  const pagedListRef = useRef<IPagedListRefType>(null);

  return (
    <Pivot
      defaultSelectedKey={tab ?? "workflows"}
      onLinkClick={(item) => {
        if (item?.props.itemKey === "back") {
          navigate("/");
        } else if (item?.props.itemKey === "workflows") {
          navigate("/workflows");
        } else {
          navigate(`/workflows/${item?.props.itemKey}`);
        }
      }}
    >
      <PivotItem itemIcon="ChromeBack" itemKey="back" />
      <PivotItem headerText="Workflows" itemKey="workflows">
        <PagedList
          selectedTab="Workflows"
          ref={pagedListRef}
          columns={[
            { key: "id", minWidth: 25, maxWidth: 55 },
            { key: "name", minWidth: 200 },
            { key: "trigger", minWidth: 100 },
            { key: "entityType", minWidth: 200 },
            { key: "dateField", minWidth: 200 },
          ]}
          getAction={getWorkflows}
          commandBarItems={[
            {
              key: "newWorkflow",
              text: "New",
              iconProps: { iconName: "Add" },
              onClick: () => navigate("/newWorkflow"),
              disabled: !profileData.roles.includes(appRoles.Admin),
            },
            {
              key: "editWorkflow",
              text: "Edit",
              iconProps: { iconName: "Edit" },
              onClick: () => navigate(`/editWorkflow?id=${selectedWorkflowId}`),
              disabled:
                !profileData.roles.includes(appRoles.Admin) || !selectedWorkflowId,
            },
            {
              key: "deleteWorkflow",
              text: "Delete",
              iconProps: { iconName: "Delete" },
              onClick: () => {
                if (selectedWorkflowId) {
                  const abortController = new AbortController();
                  deleteWorkflow(abortController, Number(selectedWorkflowId)).then(
                    () => {
                      pagedListRef.current?.refresh();
                      setSuccessMessage(
                        `Workflow with id: ${selectedWorkflowId} deleted.`
                      );
                    }
                  );
                }
              },
              disabled:
                !profileData.roles.includes(appRoles.Admin) || !selectedWorkflowId,
            },
          ]}
          onSelectionChange={(selection: any) => {
            setSelectedWorkflowId(Number(selection.id));
          }}
          onRowDoubleClicked={(selection: any) =>
            navigate(`/editWorkflow?id=${selection.id}`)
          }
        ></PagedList>
      </PivotItem>
      <PivotItem headerText="Workflow executions" itemKey="workflowExecs">
        <WorkflowExecs />
      </PivotItem>
    </Pivot>
  );
};

export default Workflows;
