import {
  forwardRef,
  useEffect,
  useState,
} from "react";

import { getDashboardPanels, getMetadata, IChartData, IMetadata } from "../../services/assetServices";
import ChartPanel from "./ChartPanel";
import { useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom } from "../../atoms/messageBarAtoms";

export interface IAssetsPanelRef {
  Resize: (width: number, height: number) => void;
}

const AssetsPanel = () => {
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const [metadata, setMetadata] = useState<IMetadata>();

  const fetchData = async () => {
    setIsInProgress(true);
    const abortController = new AbortController();

    try {
      const data: IMetadata = await getMetadata(abortController, "Asset");
      setMetadata(data);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, []);

  return <ChartPanel
    getDashboardPanelData={async (abortController: AbortController, options: string) => {
      return await getDashboardPanels(
        abortController,
        "Asset",
        options
      );
    }}
    getOptions={() => {
      const options = [
        { key: "Cat", text: "Category" },
        { key: "Zone", text: "Zone" },
        ...(metadata?.fields.filter(f => f.fieldType != "Image")
          .map(f => ({ key: f.name, text: f.label })) ?? [])
      ];
      return options;
    }} />;
};

export default forwardRef(AssetsPanel);
