import { appRoles } from "../../authConfig";
import PagedList, { IPagedListRefType } from "../../common/PagedList";
import { getWorkflowExecs } from "../../services/assetServices";
import { useAtomValue, useSetAtom } from "jotai";
import { profileDataAtom } from "../../atoms/authAtoms";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { successMessageAtom } from "../../atoms/messageBarAtoms";

const WorkflowExecs = () => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const [selectedWorkflowExecId, setSelectedWorkflowExecId] = useState<Number>();

  const pagedListRef = useRef<IPagedListRefType>(null);

  return (
    <PagedList
      selectedTab="WorkflowExecs"
      ref={pagedListRef}
      columns={[
        { key: "id", minWidth: 25, maxWidth: 55 },
        { key: "uuid", minWidth: 200 },
        { key: "entityType", minWidth: 50, maxWidth: 75 },
        { key: "dateField", minWidth: 50, maxWidth: 75 },
        { key: "triggeredAt", minWidth: 200 },
        { key: "completedAt", minWidth: 200 },
        { key: "status", minWidth: 100 },
      ]}
      getAction={getWorkflowExecs}
      commandBarItems={[
        {
          key: "editWorkflowExec",
          text: "Detail",
          iconProps: { iconName: "CustomList" },
          onClick: () => navigate(`/showWorkflowExec?id=${selectedWorkflowExecId}`),
          disabled:
            !profileData.roles.includes(appRoles.Admin) || !selectedWorkflowExecId,
        },
      ]}
      onSelectionChange={(selection: any) => {
        setSelectedWorkflowExecId(Number(selection.id));
      }}
      onRowDoubleClicked={(selection: any) =>
        navigate(`/editWorkflowExec?id=${selection.id}`)
      }
    ></PagedList>
  );
};

export default WorkflowExecs;
