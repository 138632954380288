import { useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import { useSetAtom } from "jotai";
import { successMessageAtom } from "../../atoms/messageBarAtoms";
import {
  ColorPicker,
  getColorFromString,
  IColor,
  Icon,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import {
  labelColumnStyle,
  valueColumnStyle,
} from "../../common/styles/FormsStyles";
import { useParams } from "react-router-dom";
import { createAssetStatus, createWorkOrderStatus, getAssetStatuses, getWorkOrderStatuses, IAssetStatus, IWorkOrderStatus, updateAssetStatus, updateWorkOrderStatus } from "../../services/assetServices";
import React from "react";

const Status = () => {
  const isSaved = useRef(false);
  let { type } = useParams();

  const setSuccessMessage = useSetAtom(successMessageAtom);
  const [icon, setIcon] = useState<string>();
  const white = getColorFromString('#ffffff')!;
  const [color, setColor] = React.useState(white);

  return (
    <>
      <div>{type}Status</div>
      <EditItem
        getAction={async (abortController, id) => {
          if (type === "workOrder") {
            const data: IWorkOrderStatus[] = await getWorkOrderStatuses(abortController);
            const status = data.find((status) => status.id === id);
            if (status) {
              setIcon(status.icon);
              setColor(getColorFromString(status.color)!);
            }
            return status;
          } else {
            const data: IAssetStatus[] = await getAssetStatuses(abortController);
            const status = data.find((status) => status.id === id);
            if (status) {
              setIcon(status.icon);
              setColor(getColorFromString(status.color)!);
            }
            return status;
          }
        }}
        newAction={async (abortController, newStatus) => {
          if (type === "asset") {
            const newStatusResponse = await createAssetStatus(abortController, {
              name: newStatus.name,
              code: newStatus.code,
              color: color.str,
              icon: icon,
            });
            setSuccessMessage(
              `Status with id: ${newStatusResponse.assetStatusId} created.`
            );
          } else {
            const newStatusResponse = await createWorkOrderStatus(abortController, {
              name: newStatus.name,
              code: newStatus.code,
              color: color.str,
              icon: icon,
            });
            setSuccessMessage(
              `Status with id: ${newStatusResponse.assetStatusId} created.`
            );
          }
        }}
        editAction={async (abortController, id, editedStatus) => {
          if (type === "asset") {
            await updateAssetStatus(abortController, id, {
              name: editedStatus.name,
              code: editedStatus.code,
              color: color.str,
              icon: icon,
            });
            setSuccessMessage(`Status with id: ${id} successfully updated.`);
          } else {
            await updateWorkOrderStatus(abortController, id, {
              name: editedStatus.name,
              code: editedStatus.code,
              color: color.str,
              icon: icon,
            });
            setSuccessMessage(`Status with id: ${id} successfully updated.`);
          }
        }}
        isSaved={isSaved}
        back={`/settings/${type}Statuses`}
        metadata={{
          fields: [
            {
              name: "name",
              fieldType: "String",
              label: "Name",
            },
          ],
          lookups: [],
          validations: [],
        }}
      >
        <Stack horizontal>
          <Label style={labelColumnStyle}>Icon</Label>
          <TextField
            style={{ width: valueColumnStyle.width - 55 }}
            value={icon}
            onChange={(ev, newValue) => setIcon(newValue)}
          />
        </Stack>
        <Stack horizontal>
          <Label style={labelColumnStyle}>Color</Label>
          <ColorPicker
            color={color}
            onChange={(ev: any, colorObj: IColor) => setColor(colorObj)}
            showPreview={true}
            styles={{
              panel: { padding: 5 },
              root: {
                maxWidth: 252,
                minWidth: 252,
              },
              colorRectangle: { height: 68 },
            }}
            strings={{
              hueAriaLabel: 'Hue',
            }}
          />
          {/* <TextField
            style={{ width: valueColumnStyle.width - 55 }}
            value={contractorId ? contractorId.toString() : ""}
            readOnly
            disabled
          />
          <CommandBarButton
            onClick={showContractorSelector}
            style={{ width: 55 }}
          >
            ...
          </CommandBarButton> */}
        </Stack>
      </EditItem>
    </>
  );
};

export default Status;
